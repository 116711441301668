import { Col, Row } from "react-grid-system";
import StyledButton from "../../common/buttons/StyledButton";
import Subtool from "../../common/Subtool";
import PaymentAmountSelector from "./PaymentAmountSelector";
import {
  OWNERSHIP_OPTIONS,
  isCardMemberAccount,
} from "../../../lib/sourceAccounts";
import SourceAccountSelect from "../../MakePayment/SourceAccountSelect";
import Select from "../../common/Select";
import StyledInput from "../../common/inputs/StyledInput";
import {
  abaNumberMaxLength,
  sourceAccountAccountNumberMaxLength,
  sourceAccountAccountNumberMinLength,
} from "../../../lib/constants/lengths";
import StyledPasswordInput from "../../StyledPasswordInput";
import PhoneNumberInput from "../../common/PhoneNumberInput";
import { FALLBACK_SUPPORTED_STATES } from "../../../lib/api/fallback.data";
import StyledZipcodeInput from "../../common/StyledZipcodeInput";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  EnrollAutopayRequest,
  GetAccountDetailsResponse,
  GetAccountResponse,
  OwnershipType,
  PaymentAmountOptions,
  PaymentMethod,
  ValidationErrors,
} from "../../../lib/api/models";
import {
  maskInput,
  INPUT_MASKS,
  dollarStringToCents,
  centsToDollarString,
} from "../../../lib/formatting";
import { useIsAgentRoleViewPCIData } from "../../../lib/roleContext";
import { NEW_SOURCE_ACCOUNT } from "../../MakePayment/contants";
import {
  useAddAutopayFormQueries,
  useAddAutopayState,
} from "./AddAutopay.hooks";
import {
  useEnrollAutopay,
  useGetBankNames,
  useGetPaymentMethods,
} from "../../../lib/api/queries";
import Spinner from "../../common/Spinner";
import StyledError from "../../StyledError";
import { reportError } from "../../../lib/bugReporting";
import {
  getNonGenericValidationList,
  mergeValidation,
  setValidationErrorsAndDisplayGeneralErrors,
} from "../../../lib/validation";
import greenCheck from "../../../assets/green-filled-check.svg";

import styles from "./AddAutopay.module.css";

const MAX_FIXED_PAYMENT_AMOUNT_CENTS = 999999;
const MIN_FIXED_PAYMENT_AMOUNT_CENTS = 2000;

const strings = {
  one: "1",
  two: "2",
  holderAndAccountInfoSubheader: "Holder and Account Info",
  ownership: "Ownership*",
  select: "Select",
  amountSubheader: "Amount",
  enroll: "Enroll in Autopay",
  abaNumber: "ABA Number",
  abaNumberRequired: "ABA Number*",
  bankNameRequired: "Bank Name*",
  accountNumber: "Account Number",
  accountNumberRequired: "Account Number*",
  confirmAccountNumber: "Confirm Account Number*",
  cardHolderFirstName: "First Name*",
  cardHolderFirstName2: "First Name 2",
  cardHolderLastName: "Last Name*",
  cardHolderLastName2: "Last Name 2",
  phoneNumber: "Phone Number",
  address: "Address*",
  unit: "Unit or Ste.",
  city: "City*",
  state: "State*",
  zip: "Zip*",
  addSourceAccount: "Add Source Account",
  editSourceAccount: "Edit Source Account",
  autofill: "Autofill",
  accountNumbersDontMatch: "Account Numbers don't match",
  addAutopay: "Add Autopay",
  somethingWentWrong: "Something went wrong loading bank names",
  paymentCentsMax: `Please enter a value below ${centsToDollarString(
    MAX_FIXED_PAYMENT_AMOUNT_CENTS + 1,
  )}.`,
  paymentCentsMin: `Please enter a value of ${centsToDollarString(
    MIN_FIXED_PAYMENT_AMOUNT_CENTS,
  )} or above.`,
  invalid: "Invalid amount",
  summary: (dwbuid: string) => `Successfully added autopay ${dwbuid}`,
  complete: "Complete",
};

type Props = {
  account: GetAccountResponse;
  accountDetails: GetAccountDetailsResponse;
  onDisplayGeneralErrors: (err: unknown) => void;
  onComplete: (summaryWithoutTime: string) => void;
  setToolInProgress: (inProgress: boolean) => void;
  className?: string;
};

const validateAccountNumbers = (
  accNumber: string,
  confirmAccNumber: string,
): string[] => {
  if (!confirmAccNumber) {
    return [];
  }

  if (accNumber !== confirmAccNumber) {
    return [strings.accountNumbersDontMatch];
  }

  return [];
};

const validateFixedAmountCents = (
  fixedAmountCents: string,
  paymentAmount?: string,
) => {
  if (!paymentAmount || paymentAmount !== "FIXED") {
    return [];
  }

  const amountCents = dollarStringToCents(fixedAmountCents);
  if (amountCents > MAX_FIXED_PAYMENT_AMOUNT_CENTS) {
    return [strings.paymentCentsMax];
  } else if (amountCents < MIN_FIXED_PAYMENT_AMOUNT_CENTS) {
    return [strings.paymentCentsMin];
  }
  return [];
};

export default function AddAutopay({
  account,
  accountDetails,
  onDisplayGeneralErrors,
  onComplete,
  setToolInProgress,
  className,
}: Props) {
  const canViewPCIData = useIsAgentRoleViewPCIData();

  const [
    formState,
    {
      generalDispatch,
      updateOwnerType,
      updateSelectedSourceAccount,
      validationMessages: sourceAccountValidationMessages,
      setValidationMessages: setSourceAccountValidationMessages,
    },
  ] = useAddAutopayState();

  const updateFormToCurrentSourceAccount = useCallback(
    (sourceAccount: PaymentMethod) => {
      if (sourceAccount.type === "checking_account") {
        updateSelectedSourceAccount(sourceAccount);
      } else {
        reportError(
          new Error(
            "Programmer Error: Response source account is not type `checking_account`",
            {
              cause: {
                id: sourceAccount.id,
                type: sourceAccount.type,
              },
            },
          ),
        );
      }
    },
    [updateSelectedSourceAccount],
  );

  const { onUpdateSourceAccount, isLoading: isSourceAccountsLoading } =
    useAddAutopayFormQueries(
      account,
      formState,
      updateFormToCurrentSourceAccount,
      setSourceAccountValidationMessages,
      onDisplayGeneralErrors,
    );

  const [networkValidationMessages, setNetworkValidationMessages] =
    useState<ValidationErrors>();
  const [localValidationMessages, setLocalValidationMessages] = useState<
    ValidationErrors | undefined
  >(undefined);
  const [paymentAmount, setPaymentAmount] = useState<PaymentAmountOptions>();
  const [fixedAmountCents, setFixedAmountCents] = useState("");

  const finalValidation = mergeValidation(
    networkValidationMessages ?? {},
    localValidationMessages ?? {},
  );

  const canEnrollAutopay =
    getNonGenericValidationList(finalValidation).length < 1 &&
    formState.isComplete &&
    !!paymentAmount &&
    (paymentAmount !== "FIXED" ||
      validateFixedAmountCents(fixedAmountCents, paymentAmount).length === 0);

  const {
    data: getPaymentMethodsResponse,
    isLoading: isGetPaymentMethodsResponseLoading,
    isError: isGetPaymentMethodsResponseError,
    error: getPaymentMethodsResponseError,
    refetch: getPaymentMethodsResponseRefetch,
  } = useGetPaymentMethods({
    partyId: account.partyId,
  });

  const {
    data: bankNamesResponse,
    isLoading: isLoadingBankNames,
    isError: isBankNamesError,
    error: bankNamesError,
    refetch: refetchBankNames,
  } = useGetBankNames(
    formState.abaNumber.length === abaNumberMaxLength
      ? formState.abaNumber
      : "",
  );

  const { mutateAsync: enrollAutopay, isLoading: isEnrollAutopayLoading } =
    useEnrollAutopay();

  const isAddingNewSourceAccount =
    formState.paymentMethodId === NEW_SOURCE_ACCOUNT;
  const isOwner = isCardMemberAccount({
    ownerType: formState.ownerType ?? "UNKNOWN",
  });
  const isLoading =
    isGetPaymentMethodsResponseLoading || isSourceAccountsLoading;

  const { sourceAccountOptions } = useMemo(() => {
    if (!getPaymentMethodsResponse) {
      return { sourceAccountOptions: [] };
    }

    let currentPM: PaymentMethod[] =
      getPaymentMethodsResponse.thirdPartyAccounts;
    switch (formState.ownerType) {
      case "ACCOUNT_OWNER":
        currentPM = getPaymentMethodsResponse.cardMemberAccounts.filter(
          (pm) => pm.ownerType === "ACCOUNT_OWNER",
        );
        break;
      case "AUTHORIZED_USER":
        currentPM = getPaymentMethodsResponse.cardMemberAccounts.filter(
          (pm) => pm.ownerType === "AUTHORIZED_USER",
        );
    }

    currentPM = currentPM.filter((pm) => pm.type === "checking_account");

    return { sourceAccountOptions: currentPM };
  }, [getPaymentMethodsResponse, formState.ownerType]);

  const clearNetworkValidationMessage = (...keys: string[]) => {
    if (!networkValidationMessages || keys.length < 1) {
      return;
    }

    setNetworkValidationMessages((prev) => {
      const next = { ...prev };
      keys.forEach((key) => {
        next[key] = [];
      });

      return next;
    });
  };

  const onUpdateSelectedSourceAccount = (id: string) => {
    if (id === NEW_SOURCE_ACCOUNT) {
      updateSelectedSourceAccount(undefined);
      return;
    }

    const sourceAccount = sourceAccountOptions.find((acc) => acc.id === id);
    if (!sourceAccount) {
      reportError(
        new Error("Programmer Error: Unable to find source account with id", {
          cause: id,
        }),
      );
      return;
    }

    if (sourceAccount.type === "checking_account") {
      updateSelectedSourceAccount(sourceAccount);
    } else {
      reportError(
        new Error(
          "Programmer Error: Selected source account is not of type checking_account",
          {
            cause: {
              id,
              type: sourceAccount.type,
            },
          },
        ),
      );
      return;
    }
  };

  const accountNumberInputsOnFocus = () => {
    setLocalValidationMessages((prev) => {
      return {
        ...prev,
        confirmAccountNumber: [],
      };
    });
  };

  const accountNumberInputsOnBlur = () => {
    setLocalValidationMessages((prev) => {
      return {
        ...prev,
        confirmAccountNumber: validateAccountNumbers(
          formState.accountNumber,
          formState.confirmAccountNumber,
        ),
      };
    });
  };

  const onAutofill = () => {
    generalDispatch({
      cardHolderFirstName: account.firstName,
      cardHolderLastName: account.lastName,
      phoneNumber: accountDetails.phoneNumber,
      addressLine1: accountDetails.addressLine1,
      addressLine2: accountDetails.addressLine2 ?? undefined,
      city: accountDetails.city,
      stateCode: accountDetails.stateCode,
      zipcode: accountDetails.zipcode,
    });
  };

  const onEnrollAutopay = (e: React.FormEvent) => {
    e.preventDefault();

    if (!canEnrollAutopay || isEnrollAutopayLoading) {
      return;
    }

    const fixedAmount = dollarStringToCents(fixedAmountCents);

    const requestBody: EnrollAutopayRequest = {
      dwbuid: account.currentDwbuid,
      amount:
        paymentAmount === "FIXED"
          ? {
              type: paymentAmount,
              amountCents: fixedAmount,
            }
          : { type: paymentAmount },
      partyId: account.partyId,
      paymentMethodId: formState.paymentMethodId,
    };

    enrollAutopay(requestBody)
      .then(() => onComplete(strings.summary(account.currentDwbuid)))
      .catch((err) =>
        setValidationErrorsAndDisplayGeneralErrors(
          err,
          setNetworkValidationMessages,
          onDisplayGeneralErrors,
        ),
      );
  };

  useEffect(() => {
    setToolInProgress(formState.isDirty);
  }, [formState.isDirty, setToolInProgress]);

  const renderAddAutopay = () => {
    return (
      <Row>
        <Col md={8}>
          <Subtool>
            <div className={styles.subheaderContainer}>
              {formState.isComplete ? (
                <img src={greenCheck} alt={strings.complete} />
              ) : (
                <div className={styles.subtoolNumberOne}>{strings.one}</div>
              )}
              <div className={styles.subheader}>
                {strings.holderAndAccountInfoSubheader}
              </div>
            </div>

            <form
              onSubmit={(e: React.FormEvent) => {
                e.preventDefault();

                onUpdateSourceAccount(isAddingNewSourceAccount);
              }}
            >
              <Row className={styles.row}>
                <Col md={3}>
                  <Select
                    selectedValue={formState.ownerType}
                    options={OWNERSHIP_OPTIONS}
                    onChange={(v: OwnershipType) => updateOwnerType(v)}
                    label={strings.ownership}
                    placeholder={strings.select}
                    validationMessages={
                      sourceAccountValidationMessages?.["ownerType"]
                    }
                    disabled={isLoading}
                  />
                </Col>
                <Col md={3}>
                  <SourceAccountSelect
                    className={styles.sourceAccountSelect}
                    selectedValue={formState.paymentMethodId}
                    accounts={sourceAccountOptions}
                    onChange={onUpdateSelectedSourceAccount}
                    onAddNew={onUpdateSelectedSourceAccount}
                    disabled={!formState.ownerType || isLoading}
                    validationMessages={
                      sourceAccountValidationMessages?.["paymentMethodId"]
                    }
                  />
                </Col>
                {formState.ownerType === "ACCOUNT_OWNER" &&
                formState.paymentMethodId ? (
                  <Col
                    className={styles.autofillBtnCol}
                    offset={{ md: 4.5 }}
                    md={1.5}
                  >
                    <StyledButton
                      className={styles.autofillBtn}
                      variant="secondary"
                      disabled={isLoading}
                      onClick={onAutofill}
                    >
                      {strings.autofill}
                    </StyledButton>
                  </Col>
                ) : null}
              </Row>
              {formState.paymentMethodId ? (
                <>
                  <Row className={styles.row}>
                    <Col md={3}>
                      <StyledInput
                        label={
                          isAddingNewSourceAccount
                            ? strings.abaNumberRequired
                            : strings.abaNumber
                        }
                        value={formState.abaNumber}
                        onChange={(e) => {
                          const newValue = maskInput(
                            INPUT_MASKS.abaNumber,
                            e.currentTarget.value,
                          );

                          generalDispatch({
                            abaNumber: newValue,
                          });
                          if (
                            formState.selectedBankName &&
                            newValue.length !== abaNumberMaxLength
                          ) {
                            generalDispatch({ selectedBankName: "" });
                          }
                        }}
                        maxLength={abaNumberMaxLength}
                        disabled={isLoading || !isAddingNewSourceAccount}
                        validationMessages={
                          sourceAccountValidationMessages?.["abaNumber"]
                        }
                      />
                    </Col>
                    <Col md={3}>
                      <Select
                        selectedValue={formState.selectedBankName}
                        options={
                          bankNamesResponse?.bankNames.map((n) => ({
                            label: n,
                            value: n,
                          })) ?? []
                        }
                        onChange={(v: string) =>
                          generalDispatch({ selectedBankName: v })
                        }
                        label={strings.bankNameRequired}
                        placeholder={strings.select}
                        disabled={
                          isLoadingBankNames ||
                          !bankNamesResponse ||
                          !isAddingNewSourceAccount
                        }
                      />
                    </Col>
                    <Col md={3}>
                      <StyledPasswordInput
                        label={
                          isAddingNewSourceAccount
                            ? strings.accountNumberRequired
                            : strings.accountNumber
                        }
                        value={formState.accountNumber}
                        onChange={(e) =>
                          generalDispatch({
                            accountNumber: maskInput(
                              INPUT_MASKS.checkingAccountNumber,
                              e.currentTarget.value,
                            ),
                          })
                        }
                        maxLength={sourceAccountAccountNumberMaxLength}
                        minLength={sourceAccountAccountNumberMinLength}
                        alwaysHideInput={!canViewPCIData}
                        onFocus={accountNumberInputsOnFocus}
                        onBlur={accountNumberInputsOnBlur}
                        disabled={isLoading || !isAddingNewSourceAccount}
                        validationMessages={
                          sourceAccountValidationMessages?.["accountNumber"]
                        }
                      />
                    </Col>
                    {isAddingNewSourceAccount ? (
                      <Col md={3}>
                        <StyledPasswordInput
                          label={strings.confirmAccountNumber}
                          value={formState.confirmAccountNumber}
                          onChange={(e) =>
                            generalDispatch({
                              confirmAccountNumber: maskInput(
                                INPUT_MASKS.checkingAccountNumber,
                                e.currentTarget.value,
                              ),
                            })
                          }
                          maxLength={sourceAccountAccountNumberMaxLength}
                          minLength={sourceAccountAccountNumberMinLength}
                          alwaysHideInput={!canViewPCIData}
                          onFocus={accountNumberInputsOnFocus}
                          onBlur={accountNumberInputsOnBlur}
                          validationMessages={
                            localValidationMessages?.["confirmAccountNumber"]
                          }
                          disabled={isLoading}
                        />
                      </Col>
                    ) : null}
                  </Row>
                  {isBankNamesError ? (
                    <Row>
                      <Col>
                        <StyledError
                          error={bankNamesError}
                          errorTitle={strings.somethingWentWrong}
                          refetch={refetchBankNames}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  <Row className={styles.row}>
                    <Col md={3}>
                      <StyledInput
                        label={strings.cardHolderFirstName}
                        value={formState.cardHolderFirstName}
                        onChange={(e) =>
                          generalDispatch({
                            cardHolderFirstName: e.currentTarget.value,
                          })
                        }
                        disabled={isLoading}
                        validationMessages={
                          sourceAccountValidationMessages?.[
                            "cardHolderFirstName"
                          ]
                        }
                      />
                    </Col>
                    <Col md={3}>
                      <StyledInput
                        label={strings.cardHolderLastName}
                        value={formState.cardHolderLastName}
                        onChange={(e) =>
                          generalDispatch({
                            cardHolderLastName: e.currentTarget.value,
                          })
                        }
                        disabled={isLoading}
                        validationMessages={
                          sourceAccountValidationMessages?.[
                            "cardHolderLastName"
                          ]
                        }
                      />
                    </Col>
                    <Col md={3}>
                      <PhoneNumberInput
                        label={strings.phoneNumber}
                        value={formState.phoneNumber}
                        onChange={(v) => generalDispatch({ phoneNumber: v })}
                        disabled={isLoading}
                        validationMessages={
                          sourceAccountValidationMessages?.["phoneNumber"]
                        }
                      />
                    </Col>
                  </Row>
                  {!isOwner ? (
                    <Row className={styles.row}>
                      <Col md={3}>
                        <StyledInput
                          label={strings.cardHolderFirstName2 ?? ""}
                          value={formState.cardHolderFirstName2}
                          onChange={(e) =>
                            generalDispatch({
                              cardHolderFirstName2: e.currentTarget.value,
                            })
                          }
                          disabled={isLoading}
                          validationMessages={
                            sourceAccountValidationMessages?.[
                              "cardHolderFirstName2"
                            ]
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <StyledInput
                          label={strings.cardHolderLastName2 ?? ""}
                          value={formState.cardHolderLastName2}
                          onChange={(e) =>
                            generalDispatch({
                              cardHolderLastName2: e.currentTarget.value,
                            })
                          }
                          disabled={isLoading}
                          validationMessages={
                            sourceAccountValidationMessages?.[
                              "cardHolderLastName2"
                            ]
                          }
                        />
                      </Col>
                    </Row>
                  ) : null}
                  <Row className={styles.row}>
                    <Col md={4.5}>
                      <StyledInput
                        label={strings.address}
                        value={formState.addressLine1}
                        onChange={(e) =>
                          generalDispatch({
                            addressLine1: e.currentTarget.value,
                          })
                        }
                        disabled={isLoading}
                        validationMessages={
                          sourceAccountValidationMessages?.["addressLine1"]
                        }
                      />
                    </Col>
                    <Col md={1.5}>
                      <StyledInput
                        label={strings.unit}
                        value={formState.addressLine2 ?? ""}
                        onChange={(e) =>
                          generalDispatch({
                            addressLine2: e.currentTarget.value,
                          })
                        }
                        disabled={isLoading}
                        validationMessages={
                          sourceAccountValidationMessages?.["addressLine2"]
                        }
                      />
                    </Col>
                    <Col md={2}>
                      <StyledInput
                        label={strings.city}
                        value={formState.city}
                        onChange={(e) =>
                          generalDispatch({ city: e.currentTarget.value })
                        }
                        disabled={isLoading}
                        validationMessages={
                          sourceAccountValidationMessages?.["city"]
                        }
                      />
                    </Col>
                    <Col md={2}>
                      <Select
                        options={FALLBACK_SUPPORTED_STATES}
                        label={strings.state}
                        selectedValue={formState.stateCode}
                        onChange={(v) => generalDispatch({ stateCode: v })}
                        placeholder={strings.select}
                        disabled={isLoading}
                        validationMessages={
                          sourceAccountValidationMessages?.["stateCode"]
                        }
                      />
                    </Col>
                    <Col md={2}>
                      <StyledZipcodeInput
                        className={styles.zipcode}
                        label={strings.zip}
                        value={formState.zipcode}
                        onChange={(v) => generalDispatch({ zipcode: v })}
                        disabled={isLoading}
                        validationMessages={
                          sourceAccountValidationMessages?.["zipcode"]
                        }
                      />
                    </Col>
                  </Row>
                  <Row className={styles.row}>
                    <Col offset={{ md: 9 }} md={3}>
                      <StyledButton
                        variant="secondary"
                        className={styles.sourceAccountButton}
                        type="submit"
                        disabled={
                          isLoading ||
                          (isAddingNewSourceAccount
                            ? !formState.canAddSourceAccount
                            : !formState.canEditSourceAccount)
                        }
                        isLoading={isSourceAccountsLoading}
                      >
                        {formState.paymentMethodId === NEW_SOURCE_ACCOUNT
                          ? strings.addSourceAccount
                          : strings.editSourceAccount}
                      </StyledButton>
                    </Col>
                  </Row>
                </>
              ) : null}
            </form>
          </Subtool>
        </Col>

        <Col md={4}>
          <Subtool>
            <div className={styles.subheaderContainer}>
              {canEnrollAutopay ? (
                <img src={greenCheck} alt={strings.complete} />
              ) : (
                <div className={styles.subtoolNumberTwo}>{strings.two}</div>
              )}
              <div className={styles.subheader}>{strings.amountSubheader}</div>
            </div>

            <form onSubmit={onEnrollAutopay}>
              <PaymentAmountSelector
                customInput={fixedAmountCents}
                selectedPaymentAmount={paymentAmount}
                onSelectPaymentAmount={(v) => {
                  setPaymentAmount(v);
                  clearNetworkValidationMessage("amountCents");
                  setLocalValidationMessages((prev) => ({
                    ...prev,
                    amountCents: [],
                  }));
                }}
                onChangeCustomInput={(v) => {
                  setFixedAmountCents(v);
                  clearNetworkValidationMessage("amountCents");
                  setLocalValidationMessages((prev) => ({
                    ...prev,
                    amountCents: [],
                  }));
                }}
                className={styles.selector}
                onBlurCustomInput={() =>
                  setLocalValidationMessages((prev) => ({
                    ...prev,
                    amountCents: validateFixedAmountCents(
                      fixedAmountCents,
                      paymentAmount,
                    ),
                  }))
                }
                customInputValidation={finalValidation["amountCents"]}
                disabled={!formState.isComplete}
              />
              <StyledButton
                className={styles.enrollmentButton}
                variant="primary"
                type="submit"
                isLoading={isEnrollAutopayLoading}
                disabled={!canEnrollAutopay}
              >
                {strings.enroll}
              </StyledButton>
            </form>
          </Subtool>
        </Col>
      </Row>
    );
  };

  return (
    <div className={className}>
      {isGetPaymentMethodsResponseLoading ? (
        <Row>
          <Col>
            <Spinner className={styles.spinner} />
          </Col>
        </Row>
      ) : isGetPaymentMethodsResponseError ? (
        <Row>
          <Col>
            <StyledError
              error={getPaymentMethodsResponseError}
              errorTitle={strings.addAutopay}
              refetch={getPaymentMethodsResponseRefetch}
            />
          </Col>
        </Row>
      ) : (
        renderAddAutopay()
      )}
    </div>
  );
}
